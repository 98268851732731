
const keyboardModelTemplate = {
    a: "tbd",
    b: "tbd",
    c: "tbd",
    d: "tbd",
    e: "tbd",
    f: "tbd",
    g: "tbd",
    h: "tbd",
    i: "tbd",
    j: "tbd",
    k: "tbd",
    l: "tbd",
    m: "tbd",
    n: "tbd",
    o: "tbd",
    p: "tbd",
    q: "tbd",
    r: "tbd",
    s: "tbd",
    t: "tbd",
    u: "tbd",
    v: "tbd",
    w: "tbd",
    x: "tbd",
    y: "tbd",
    z: "tbd",
};

export const getKeyboardModelFromGameBoard = (gameBoardModel) => {
    let model = {...keyboardModelTemplate};

    const rowKeys = Object.keys(gameBoardModel);
    for (let i = 0; i < rowKeys.length; i++ ) {
        
        let rowKey = rowKeys[i];
        let letterKeys = Object.keys(gameBoardModel[rowKey]);

        for (let j = 0; j < letterKeys.length; j++ ) {
            let letterKey = letterKeys[j];
            let letter = gameBoardModel[rowKey][letterKey].letter;
            let state = gameBoardModel[rowKey][letterKey].state;
            
            let notCorrect = model[letter] !== "correct";
            let notCorrectPresent = notCorrect && model[letter] !== "present";
            let notCorrectPresentAbsent = notCorrectPresent && model[letter] !== "absent";

            if ((state === "empty" || state === "tbd") && notCorrectPresentAbsent) {
                model[letter] = "tbd";
            } else if (state === "absent" && notCorrectPresent) {
                model[letter] = "absent";
            } else if (state === "present" && notCorrect) {
                model[letter] = "present";
            } else if (state === "correct") {
                model[letter] = "correct";
            };
        };
    };
    return model;
}