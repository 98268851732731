import React, { useState, useEffect } from 'react';
import { Alert } from '../Alert';
import { GameBoard } from '../GameBoard/GameBoard';
import { KeyboardWithState } from '../KeyBoard/KeyboardWithState';
import { getGameBoardModelFromGame, getBoardSize} from '../../models/gameBoardModel';
import { getKeyboardModelFromGameBoard } from '../../models/keyboardModel';
import { gameWon, onLetter, onBackspace, onEnter, getCurrentOrNewGame } from '../../controllers/gameController'
import { saveGame } from '../../models/gameModel';
import "./Game.css";


export const Game = ( props ) => {

    const [game, setGame] = useState(getCurrentOrNewGame());
    const [isNotAWordAlert, setIsNotAWordAlert] = useState(false);
    const [showWordAlert, setShowWordAlert] = useState(false);

    useEffect(() => {
        if (game.gameIsOver && !gameWon(game)) {
            setShowWordAlert(true); 
        };

        saveGame(game);
    }, [game]);

    const [boardSize, setBoardSize] = useState(getBoardSize());
    useEffect(() => {
        function handleResize() {
            setBoardSize(getBoardSize());
        };

        window.addEventListener('resize', handleResize);
        
        return function cleanup() {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let gameBoardModel = getGameBoardModelFromGame(game);
    let keyboardModel = getKeyboardModelFromGameBoard(gameBoardModel);

    return(
        <div className="wordy__game">
            <div className="wordy__board_container">
                <GameBoard model={gameBoardModel} style={boardSize}></GameBoard>
            </div>
            <div className="wordy__alert_container">
                <Alert 
                    showAlert={isNotAWordAlert} 
                    setShowAlert={setIsNotAWordAlert} 
                    message="Not in word list" 
                    varient="info" 
                    delay={1.5} 
                />
            </div>
            <div className="wordy__alert_container">
                <Alert 
                    showAlert={showWordAlert} 
                    setShowAlert={setShowWordAlert} 
                    message={`${game.word.toUpperCase()}`}
                    varient="info" 
                    delay={150} 
                />
            </div>
            <KeyboardWithState 
                letterCallback={onLetter.bind(this, setGame, game)}
                enterCallback={onEnter.bind(this, setGame, setIsNotAWordAlert, game)}
                backspaceCallback={onBackspace.bind(this, setGame, game)}
                model={keyboardModel}>
            </KeyboardWithState>
        </div>
    );
}

Game.defaultProps = {
};