import React from 'react';
import PropTypes from 'prop-types';
import './ExitHeader.css';
import { IoCloseSharp } from "react-icons/io5";

export const ExitHeader = ({title, exitCallback, ...props}) => {
    return (
    <div className="header_exit">
            <div className="header_left_exit"> 
            </div>
            <div className="header_title_exit">{title}</div>
            <div className="header_right_exit">
                <button className="button_icon" onClick={exitCallback}>
                    <IoCloseSharp className="icon"/>
                </button>
            </div>
    </div>
    );
};

ExitHeader.propTypes = {
    title: PropTypes.string,
    exitCallback: PropTypes.func,
};

ExitHeader.defaultProps = {
    title: null,
    exitCallback: () => {},
};
