import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Alert.css';

export const Alert = ({ showAlert, setShowAlert, message, varient, delay, ...props}) => {

    // On componentDidMount set the timer
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (showAlert) {
                setShowAlert(false);
            };
        }, delay * 1000);

        return () => {
            clearTimeout(timeOutId);
        };
    });

    // If show is false the component will return null and stop here
    if (!showAlert) {
        return null;
    }
    
    return (
        <div className={`wordy__alert wordy__alert_${varient}`}>
            {message}
        </div>
    );
};

Alert.propTypes = {
    showAlert: PropTypes.bool,
    setShowAlert: PropTypes.func,
    varient: PropTypes.string,
    message: PropTypes.string,
    delay: PropTypes.number,
};

Alert.defaultProps = {
    showAlert: true,
    setShowAlert: function() {},
    varient: "info",
    message: null,
    delay: 5
};