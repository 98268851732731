import React from 'react';
import PropTypes from 'prop-types';
import './Header.css';
import { IoSettingsSharp } from "react-icons/io5";
import { TiThMenu } from "react-icons/ti";


export const Header = ({title, theme, openNavCallback, openSettingsCallback, ...props}) => {
    return (
    <div className="header">
        <div className="header_left">
            <button className="button_icon">
                <TiThMenu className="icon" onClick={openNavCallback} />
            </button>
        </div>
        <div className="header_title">{title}</div>
        <div className="header_right">
            <button className="button_icon" onClick={openSettingsCallback}>
                <IoSettingsSharp className="icon" />
            </button>
        </div>
    </div>
    );
};

Header.propTypes = {
    title: PropTypes.string,
    theme: PropTypes.bool,
    openNavCallback: PropTypes.func,
    openSettingsCallback: PropTypes.func,
};

Header.defaultProps = {
    title: null,
    theme: false,
    openNavCallback: () => {},
    openSettingsCallback: () => {},
};