
const isPresent = (letter, word) => {
    return word.includes(letter);
};

const isCorrect  = (letter, word, index) => {
    return letter === word[index];
};

const getCharIndices = (char, word) => {
    let indices = [];
    for (let i = 0; i < word.length; i++ ) {
        if (char === word[i]) {
            indices.push(i);
        };
    };
    return indices;
};

const tooManyRepeats = (letter, tile, index, letters, word) => {
    let correctLocations = getCharIndices(letter, word);
    let guessLocations = getCharIndices(letter, letters);

    if (correctLocations.includes(index)) {
        tile.state = "correct";
    } else {

        //remove correct locations from both lists
        for (let i = correctLocations.length-1; i >= 0; i--) {
            if (guessLocations.includes(correctLocations[i])) {
                let j = guessLocations.indexOf(correctLocations[i]);
                guessLocations.splice(j, 1);
                correctLocations.splice(i, 1);
            };
        };

        //sort the remaining guess locations
        correctLocations.sort();
        guessLocations.sort();

        // (guess array index > correctLocations.length - 1) : absent ? present
        let letterIndexOf = guessLocations.indexOf(index);
        if (letterIndexOf > correctLocations.length - 1) {
            tile.state = "absent"
        } else {
            tile.state = "present"
        };
    };
};

const tooFewOrEqualRepeats = (letter, tile, index, word) => {
    noRepeats(letter, tile, index, word);
};

const noRepeats = (letter, tile, index, word) => {
    if (isCorrect(letter, word, index)) {
        tile.state = "correct";
    } else if (isPresent(letter, word)) {
        tile.state = "present";
    } else {
        tile.state = "absent";
    };
};

export const getTileModel = (index, submitted, letters, word) => {
    const letter = letters[index];
    const repeatLetters = (letters.match(new RegExp(letter, "g")) || []).length;
    const repeatWord = (word.match(new RegExp(letter, "g")) || []).length;

    let tile = {
        letter: letter,
        state: letters[index] ? "tbd" : "empty",
    };

    if (submitted) {
        if (repeatWord === 0 || (repeatLetters === 1 && repeatWord === 1)) {
            noRepeats(letter, tile, index, word);
        } else if (repeatLetters > repeatWord) {
            tooManyRepeats(letter, tile, index, letters, word);
        } else {
            tooFewOrEqualRepeats(letter, tile, index, word);
        };
    };

    return tile;
};
