import React, { useEffect, useState } from 'react';
import './App.css';
import './styles/themes.css'
import { Header } from './stories/Header/Header';
import { Game } from './stories/WordyGame/Game';
import { NavMenu } from './stories/NavMenu/NavMenu';
import { SettingsOverlay } from './stories/Settings/SettingsOverlay';
import { getInitialTheme, saveTheme, validateTheme } from './styles/themes';

function App() {

  useEffect(() => {
    document.title = "Wordy - Data Space Dev"
  }, [])

  const [theme, setTheme] = useState(getInitialTheme());
  const [navMenu, setNavMenu] = useState(false);
  const [settingsOverlay, setSettingsOverlay] = useState(false);

  const openNavCallback = () => {
    setNavMenu(true);
  };

  const closeNavCallback  = () => {
    setNavMenu(false);
  };

  const openSettingsCallback = () => {
    setSettingsOverlay(true);
  };

  const closeSettingsCallback = () => {
    setSettingsOverlay(false);
  };

  const setThemeCallback = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    saveTheme(newTheme);
    setTheme(validateTheme(newTheme));
  };

  const themeBool = theme === "light" ? false : true;

  return (
    <div className={`App ${theme}`}>
      <NavMenu 
        visible={navMenu}
        closeCallback={closeNavCallback}>
      </NavMenu>
      <SettingsOverlay 
        name="SETTINGS" 
        visible={settingsOverlay} 
        closeOverlayCallback={closeSettingsCallback}
        theme={themeBool}
        setThemeCallback={setThemeCallback}>
      </SettingsOverlay>
      <Header 
        title="Wordy" 
        theme={themeBool} 
        openNavCallback={openNavCallback} 
        openSettingsCallback={openSettingsCallback}> 
      </Header>
      <Game></Game>
    </div>
  );
}

export default App;
