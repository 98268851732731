
import { dictionary } from "../models/dictionaryModel";
import { newGameTemplate } from "../models/gameModel";


export const getCurrentOrNewGame = () => {
    const savedGame = JSON.parse(localStorage.getItem("dataspacedev-wordy-game"));

    if (savedGame && savedGame?.gameIsOver) {
        return generateNewGame();
    };

    return savedGame || generateNewGame();
};

export const generateNewGame = () => {
    return {...newGameTemplate, word: dictionary[Math.floor(Math.random()*dictionary.length)]};
};

export const onLetter = (setGame, game, letter) => {
    const i = game.currentAttempt - 1;

    if (!gameIsOver(game) && !game.attempts[i].letters) {
        game.attempts[i].letters = letter;
    } else if (!gameIsOver(game) && game.attempts[i].letters.length !== game.word.length) {
        game.attempts[i].letters = game.attempts[i].letters + letter;
    };

    setGame({...game});
};

export const onBackspace = (setGame, game) => {
    const i = game.currentAttempt - 1;

    if (!gameIsOver(game) && game.attempts[i].letters) {
        game.attempts[i].letters = game.attempts[i].letters.slice(0, -1);
    };

    setGame({...game});
};

export const onEnter = (setGame, setIsNotAWord, game) => {
    const i = game.currentAttempt - 1;
    const isCorrectwordyngth = game.attempts[i].letters?.length === game.word.length;
    const isWord = isCorrectwordyngth ? inWordList(game.attempts[i].letters) : false;
    const gameOver = gameIsOver(game);

    if (!isCorrectwordyngth || gameOver) {
        return;
    };

    if (!gameOver && isWord) {
        game.attempts[i].submitted = true;
        if (!gameWon(game) && game.currentAttempt < game.numberOfAttempts) {
            game.currentAttempt++;
        };
    } else {
        setIsNotAWord(true);
        return;
    };

    if (gameIsOver(game)) {
        onGameOver(game);
    };

    setGame({...game});
};

export const inWordList = (letters) => {
    if (dictionary.includes(letters)) {
        return true;
    };
    return false;
};

export const gameWon = (game) => {
    const i = game.currentAttempt - 1;

    if (game.attempts[i].letters === game.word && game.attempts[i].submitted) {
        return true;
    } else {
        return false;
    };
};

export const gameIsOver = (game) => {
    const i = game.currentAttempt - 1;
    const usedAllAttempts = i === game.numberOfAttempts - 1 && game.attempts[i].submitted;

    if (gameWon(game) || usedAllAttempts) {
        return true;
    } else {
        return false;
    };
};

export const onGameOver = (game) => {
    game.gameIsOver = true;
};
