import React from 'react';
import PropTypes from 'prop-types';
import './KeyWithState.css';

export const Key = ({ letter, className, callback, ...props}) => {
    return (<button className={className} onClick={callback}>
                {letter}
            </button>);
};

Key.propTypes = {
    letter: PropTypes.string,
};

Key.defaultProps = {
    letter: null,
    className: "wordy__key wordy__key_tbd"
};