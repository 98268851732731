
import { getTileModel } from "./tileModel";

export const getTileModelsFromGameRow = (submitted, letters, word) => {
    if (submitted == null) {
        submitted = false;
    };

    if (letters == null) {
        letters = "";
    };

    let row = {};
    for ( let i = 0; i < word.length; i++ ) {
        row[i] = getTileModel(i, submitted, letters, word);
    };
    
    return row;
};