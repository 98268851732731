import React from 'react';
import PropTypes from 'prop-types';
import './GameRow.css';
import { Tile } from './Tile';

export const GameRow = ({ model, ...props }) => {
    
    const tileList = Object.keys(model).map((key) => 
        <Tile key={key} model={model[key]}></Tile>
    );

    return (
        <div className="wordy__game_row">{tileList}</div>
    );
};

GameRow.propTypes = {
    model: PropTypes.object
};

GameRow.defaultProps = {
    model: {
        1: {
            letter: null,
            state: "empty"
        },
        2: {
            letter: null,
            state: "empty"
        },
        3: {
            letter: null,
            state: "empty"
        },
        4: {
            letter: null,
            state: "empty"
        },
        5: {
            letter: null,
            state: "empty"
        },
    },
};