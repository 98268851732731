import React from 'react';
import PropTypes from 'prop-types';
import './GameBoard.css';
import { GameRow } from './GameRow';


export const GameBoard = ({ model, style, ...props }) => {

    const rowList = Object.keys(model).map((key) => 
        <GameRow key={key} model={model[key]}></GameRow>
    );

    return (
        <div className="wordy__game_board" style={style}>{rowList}</div>
    );
};

GameBoard.propTypes = {
    model: PropTypes.object,
};

GameRow.defaultProps = {
    model: {
        0: {
            1: {
                letter: null,
                state: "empty"
            },
            2: {
                letter: null,
                state: "empty"
            },
            3: {
                letter: null,
                state: "empty"
            },
            4: {
                letter: null,
                state: "empty"
            },
            5: {
                letter: null,
                state: "empty"
            }
        },
        1: {
            1: {
                letter: null,
                state: "empty"
            },
            2: {
                letter: null,
                state: "empty"
            },
            3: {
                letter: null,
                state: "empty"
            },
            4: {
                letter: null,
                state: "empty"
            },
            5: {
                letter: null,
                state: "empty"
            }
        },
        2: {
            1: {
                letter: null,
                state: "empty"
            },
            2: {
                letter: null,
                state: "empty"
            },
            3: {
                letter: null,
                state: "empty"
            },
            4: {
                letter: null,
                state: "empty"
            },
            5: {
                letter: null,
                state: "empty"
            }
        },
        3: {
            1: {
                letter: null,
                state: "empty"
            },
            2: {
                letter: null,
                state: "empty"
            },
            3: {
                letter: null,
                state: "empty"
            },
            4: {
                letter: null,
                state: "empty"
            },
            5: {
                letter: null,
                state: "empty"
            }
        },
        4: {
            1: {
                letter: null,
                state: "empty"
            },
            2: {
                letter: null,
                state: "empty"
            },
            3: {
                letter: null,
                state: "empty"
            },
            4: {
                letter: null,
                state: "empty"
            },
            5: {
                letter: null,
                state: "empty"
            }
        },
        5: {
            1: {
                letter: null,
                state: "empty"
            },
            2: {
                letter: null,
                state: "empty"
            },
            3: {
                letter: null,
                state: "empty"
            },
            4: {
                letter: null,
                state: "empty"
            },
            5: {
                letter: null,
                state: "empty"
            }
        }
    }
};