import { getTileModelsFromGameRow } from './rowModel';

export const MAX_BOARD_HEIGHT = 420; 
export const BOARD_ASPECT_RATIO = 0.85;
export const HEADER_HEIGHT = 50;
export const KEYBOARD_HEIGHT = 200;

export function getBoardSize() {
    const clientHeight = window.innerHeight - HEADER_HEIGHT - KEYBOARD_HEIGHT;
    const height = clientHeight < MAX_BOARD_HEIGHT ? clientHeight : MAX_BOARD_HEIGHT;
    return {height: height, width: height * BOARD_ASPECT_RATIO};
};

export const getGameBoardModelFromGame = (game) => {
    let model = {};
    for (let i = 0; i < game.numberOfAttempts; i++) {
        model[i] = getTileModelsFromGameRow(game.attempts[i].submitted, game.attempts[i].letters, game.word);
    }
    return model;
};