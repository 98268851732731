
export const newGameTemplate = {
    word: null,
    numberOfAttempts: 6,
    currentAttempt: 1,
    gameIsOver: false,
    attempts: {
        0: {
            letters: "",
            submitted: false
        },
        1: {
            letters: "",
            submitted: false
        },
        2: {
            letters: "",
            submitted: false
        },
        3: {
            letters: "",
            submitted: false
        },
        4: {
            letters: "",
            submitted: false
        },
        5: {
            letters: "",
            submitted: false
        },
    }
};

export const saveGame = (game) => {
    localStorage.setItem("dataspacedev-wordy-game", JSON.stringify(game));
};