import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from './Toggle';
import './ToggleSetting.css';


export const ToggleSetting = ({title, description, checked, onToggle, ...props}) => {
    return (
    <div className="setting">
        <div className="text">
            <div className='title'>
                {title}
            </div>
            <div className='description'>
                {description}
            </div>
        </div>
        <div className='control'>
            <Toggle checked={checked} onToggle={onToggle}></Toggle>
        </div>
    </div>
    );
};

ToggleSetting.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    checked: PropTypes.bool,
    onToggle: PropTypes.func,
};

ToggleSetting.defaultProps = {
    title: null,
    description: null,
    checked: false,
    onToggle: () => {}
};
