import React, { useState } from 'react';
import './Toggle.css';

export const Toggle = ({checked, onToggle, ...props}) => {

    const [value, setValue] = useState(checked);

    const callOnToggle = () => {
        setValue(!value);
        onToggle();
    }

    return (
    <label className="switch">
        <input type="checkbox" checked={value} onChange={callOnToggle}/>
        <span className="slider round"></span>
    </label>
    );
};

Toggle.defaultProps = {
    checked: false,
    onToggle: () => {}
};

