import React from 'react';
import PropTypes from 'prop-types';
import { ExitHeader } from "../Header/ExitHeader";
import { ToggleSetting } from "./ToggleSetting";
import './SettingsOverlay.css';


export const SettingsOverlay = ({name, visible, closeOverlayCallback, theme, setThemeCallback, ...props}) => {

    const style = {
        zIndex: visible ? "2000" : "-2000",
    };
    
    return (
    <div className="settings_overlay" style={style}>
        <div className="settings_content">
            <ExitHeader 
                title={name} 
                exitCallback={closeOverlayCallback}>
            </ExitHeader>
            <ToggleSetting 
                title={"Dark Theme"} 
                checked={theme} 
                onToggle={setThemeCallback}>
            </ToggleSetting>
        </div>
    </div>
    );
};

SettingsOverlay.propTypes = {
    name: PropTypes.string,
    visible: PropTypes.bool,
    closeOverlayCallback: PropTypes.func,
    theme: PropTypes.bool,
    setThemeCallback: PropTypes.func,
};

SettingsOverlay.defaultProps = {
    name: "test",
    visible: false,
    closeOverlayCallback: () => {},
    theme: false,
    setThemeCallback: () => {},
};