import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Key } from "./KeyWithState"
import './Keyboard.css';


export const KeyboardWithState = ({letterCallback, enterCallback, backspaceCallback, model, ...props}) => {

    const letters = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    const keyPressCallback = (event) => {
        if (event.key === "Enter") {
            enterCallback();
        } else if (event.key === "Backspace") {
            backspaceCallback();
        } else if (letters.includes(event.key)) {
            letterCallback(event.key);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', keyPressCallback);

        return function cleanup() {
          document.removeEventListener('keydown', keyPressCallback);
        }
    });

    return (
        <div className="wordy__keyboard">
            <div className="wordy__key-row">
                <Key className={`wordy__key wordy__key_${model.q}`} letter="q" callback={letterCallback.bind(this, "q")}/>
                <Key className={`wordy__key wordy__key_${model.w}`} letter="w" callback={letterCallback.bind(this, "w")}/>
                <Key className={`wordy__key wordy__key_${model.e}`} letter="e" callback={letterCallback.bind(this, "e")}/>
                <Key className={`wordy__key wordy__key_${model.r}`} letter="r" callback={letterCallback.bind(this, "r")}/>
                <Key className={`wordy__key wordy__key_${model.t}`} letter="t" callback={letterCallback.bind(this, "t")}/>
                <Key className={`wordy__key wordy__key_${model.y}`} letter="y" callback={letterCallback.bind(this, "y")}/>
                <Key className={`wordy__key wordy__key_${model.u}`} letter="u" callback={letterCallback.bind(this, "u")}/>
                <Key className={`wordy__key wordy__key_${model.i}`} letter="i" callback={letterCallback.bind(this, "i")}/>
                <Key className={`wordy__key wordy__key_${model.o}`} letter="o" callback={letterCallback.bind(this, "o")}/>
                <Key className={`wordy__key wordy__key_${model.p}`} letter="p" callback={letterCallback.bind(this, "p")}/>
            </div>
            <div className="wordy__key-row">
                <div className="wordy__spacer-half"></div>
                <Key className={`wordy__key wordy__key_${model.a}`} letter="a" callback={letterCallback.bind(this, "a")}/>
                <Key className={`wordy__key wordy__key_${model.s}`} letter="s" callback={letterCallback.bind(this, "s")}/>
                <Key className={`wordy__key wordy__key_${model.d}`} letter="d" callback={letterCallback.bind(this, "d")}/>
                <Key className={`wordy__key wordy__key_${model.f}`} letter="f" callback={letterCallback.bind(this, "f")}/>
                <Key className={`wordy__key wordy__key_${model.g}`} letter="g" callback={letterCallback.bind(this, "g")}/>
                <Key className={`wordy__key wordy__key_${model.h}`} letter="h" callback={letterCallback.bind(this, "h")}/>
                <Key className={`wordy__key wordy__key_${model.j}`} letter="j" callback={letterCallback.bind(this, "j")}/>
                <Key className={`wordy__key wordy__key_${model.k}`} letter="k" callback={letterCallback.bind(this, "k")}/>
                <Key className={`wordy__key wordy__key_${model.l}`} letter="l" callback={letterCallback.bind(this, "l")}/>
                <div className="wordy__spacer-half"></div>
            </div>
            <div className="wordy__key-row">
                <Key className="wordy__key_one_and_a_half" letter="enter" callback={enterCallback}/>
                <Key className={`wordy__key wordy__key_${model.z}`} letter="z" callback={letterCallback.bind(this, "z")}/>
                <Key className={`wordy__key wordy__key_${model.x}`} letter="x" callback={letterCallback.bind(this, "x")}/>
                <Key className={`wordy__key wordy__key_${model.c}`} letter="c" callback={letterCallback.bind(this, "c")}/>
                <Key className={`wordy__key wordy__key_${model.v}`} letter="v" callback={letterCallback.bind(this, "v")}/>
                <Key className={`wordy__key wordy__key_${model.b}`} letter="b" callback={letterCallback.bind(this, "b")}/>
                <Key className={`wordy__key wordy__key_${model.n}`} letter="n" callback={letterCallback.bind(this, "n")}/>
                <Key className={`wordy__key wordy__key_${model.m}`} letter="m" callback={letterCallback.bind(this, "m")}/>
                <Key className="wordy__key_one_and_a_half" letter="back" callback={backspaceCallback}/>
            </div>
        </div>
    );

};

Key.propTypes = {
     letterCallback: PropTypes.func,
     backspaceCallback: PropTypes.func,
     enterCallback: PropTypes.func,
     model: PropTypes.object,
};

Key.defaultProps = {
    letterCallback: function(letter) {
        console.log(`{letter} key was triggered`);
    },
    enterCallback: function() {
        console.log("enter key was triggered");
    },
    backspaceCallback: function() {
        console.log("backspace key was pressed");
    },
    model: {
        a: "tbd",
        b: "tbd",
        c: "tbd",
        d: "tbd",
        e: "tbd",
        f: "tbd",
        g: "tbd",
        h: "tbd",
        i: "tbd",
        j: "tbd",
        k: "tbd",
        l: "tbd",
        m: "tbd",
        n: "tbd",
        o: "tbd",
        p: "tbd",
        q: "tbd",
        r: "tbd",
        s: "tbd",
        t: "tbd",
        u: "tbd",
        v: "tbd",
        w: "tbd",
        x: "tbd",
        y: "tbd",
        z: "tbd",
    }
};