import React from 'react';
import PropTypes from 'prop-types';
import { ExitHeader } from '../Header/ExitHeader';
import './NavMenu.css';


export const NavMenu = ({visible, closeCallback, ...props}) => {

    const style = {
        zIndex: visible ? "2000" : "-2000",
    };
    
    return (
    <div className="nav_overlay" style={style} onClick={closeCallback}>
        <div className="nav_content">
            <ExitHeader 
                title={null}
                exitCallback={closeCallback}>
            </ExitHeader>
            <div className='temp'>Coming soon...</div>
        </div>
    </div>
    );
};

NavMenu.propTypes = {
    visible: PropTypes.bool,
    closeOverlayCallback: PropTypes.func,
};

NavMenu.defaultProps = {
    visible: false,
    closeOverlayCallback: () => {},
};