import React from 'react';
import PropTypes from 'prop-types';
import './Tile.css';


export const Tile = ({model, ...props}) => {
    const classNameBase = "wordy__tile_base";
    const className = "wordy__tile_" + model.state;

    return (
        <div className={ classNameBase + " " + className }>
            { model.letter }
        </div>
    );
}


Tile.propTypes = {
    model: PropTypes.object,
};

Tile.defaultProps = {
    model: {
        letter: null,
        state: "empty",
    }
};