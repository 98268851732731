

export const themes = {
    light: "light",
    dark: "dark"
};

export const validateTheme = (theme) => {
    if (!themes?.[theme]) {
        return themes.light;
    };
    return theme;
};

export const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("dataspacedev-wordy-theme");
    return validateTheme(savedTheme);
};

export const saveTheme = (theme) => {
    theme = validateTheme(theme);
    localStorage.setItem("dataspacedev-wordy-theme", theme);
};